import "@hotwired/turbo-rails"
import 'arrive'
import hljs from 'highlight.js'
import * as bootstrap from 'bootstrap'

// make all external links open in new tab
document.arrive('.richtext', { existing: true }, function(el) {
  const selector = "a[href^='http']:not([href^='http://hosting.amagical.net']):not([href^='https://hosting.amagical.net'])"
  el.querySelectorAll(selector).forEach(function (e) {e.setAttribute("target", "_blank") })
})

// alerts
document.arrive('.message .alert', { existing: true }, function(el) {
  setTimeout(function() {
    bootstrap.Alert.getOrCreateInstance(el).close()
  }, 5000)
})

// highlighter
document.arrive('pre code', { existing: true }, function(el) {
  if (!el.dataset.highlighted) { hljs.highlightElement(el) }
})

// scroll to anchor when location.hash exists on load
document.addEventListener('turbo:load', function(event) {
  if (document.location.hash) { document.querySelector(document.location.hash)?.scrollIntoView(true) }
})

// CSS Styles
import '~/stylesheets/application.scss'
